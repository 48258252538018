<template>
    <v-container>
        <v-card class="text-center pb-10" v-if="loading">
            <!-- <v-progress-circular
            :size="70"
            :width="7"
            color="guacamole"
            indeterminate
            ></v-progress-circular> -->
        
            <h1 class="pt-10">You are about to create a new incident</h1>
            <h3>Are you sure?</h3>
            <v-card-text>
            <v-btn color="green" class="ma-2" @click="createIncident">YES</v-btn>
            <v-btn color="red" class="ma-2" @click="$router.back()">NO</v-btn>
            </v-card-text>
        </v-card>
        <!-- <div v-if="incident">
            <span color="guacamole">SUCCESSSSSSSS</span>
            <p>The incident is:</p>
            <p>{{ incident }}</p>
        </div>
        <div v-if="error">
            <h4>Error mate</h4>
            <p>{{ error }}</p>
        </div> -->
    </v-container>
</template>


<script>
export default {
    data: function() {
        return {
            error: '',
            incident: '',
            loading: true
        }
    },
    mounted() {
        // this.$store.dispatch('incidents/createIncident').then(incident => {
        //     this.incident = incident
        //     console.log(incident)
        //     this.loading = false
        //     // redirect to the edit form now!
        //     this.$router.push({name:'Informant', params: {'entrycode': incident.entrycode}})
        // }).catch(error => {
        //     this.loading = false
        //     this.error = error
        // })
    },
    methods: {
        createIncident () {
            this.$store.dispatch('incidentsnew/createIncident').then(incident => {
            this.incident = incident
            console.log(incident)
            this.loading = false
            // redirect to the edit form now!
            this.$router.push({name:'Informant', params: {'entrycode': incident.entrycode}})
            }).catch(error => {
                this.loading = false
                this.error = error
            })
        }
    }

}
</script>
